import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export interface IAppContextProps {
	defaultCoffeeImage: string;
	defaultDesertImage: string;
	setDefaultCoffeeImage: (value: string) => void;
	setDefaultDesertImage: (value: string) => void;
}
const AppContext = createContext<IAppContextProps>({} as IAppContextProps);

interface IAppContextProviderProps {
	children: ReactNode;
}
export const AppContextProvider: FC<IAppContextProviderProps> = ({ children }) => {
	const appStoredData = localStorage.getItem('mudavim_appData');
	const [defaultCoffeeImage, setDefaultCoffeeImage] = useState<string>(
		appStoredData ? JSON.parse(appStoredData).coffeeImage : '',
	);
	const [defaultDesertImage, setDefaultDesertImage] = useState<string>(
		appStoredData ? JSON.parse(appStoredData).desertImage : '',
	);

	useEffect(() => {
		localStorage.setItem(
			'mudavim_appData',
			JSON.stringify({ coffeeImage: defaultCoffeeImage, desertImage: defaultDesertImage }),
		);
	}, [appStoredData, defaultCoffeeImage, defaultDesertImage]);

	const value = useMemo(
		() => ({
			defaultCoffeeImage,
			setDefaultCoffeeImage,
			defaultDesertImage,
			setDefaultDesertImage,
		}),
		[defaultCoffeeImage, setDefaultCoffeeImage, defaultDesertImage, setDefaultDesertImage],
	);
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
AppContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppContext;

import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { BranchData, BusinessData } from '../common/data/business-data';

export interface IBusinessContextProps {
	business: BusinessData;
	setBusiness({}: BusinessData): void;
	businessList: BusinessData[];
	setBusinessList({}: BusinessData[]): void;
	addBranch(businessId: string, branch: BranchData): void;
	updateBranch(businessId: string, branch: BranchData): void;
	deleteBranch(businessId: string, branchId: string): void;
}
const BusinessContext = createContext<IBusinessContextProps>({} as IBusinessContextProps);

interface IBusinessContextProviderProps {
	children: ReactNode;
}
export const BusinessContextProvider: FC<IBusinessContextProviderProps> = ({ children }) => {
	const businessStoredData = localStorage.getItem('mudavim_businessData');
	const [business, setBusiness] = useState<BusinessData>(
		businessStoredData ? JSON.parse(businessStoredData) : {},
	);

	const businessListStoredData = localStorage.getItem('mudavim_businessListData');
	const [businessList, setBusinessList] = useState<BusinessData[]>(
		businessListStoredData ? JSON.parse(businessListStoredData) : [],
	);

	useEffect(() => {
		localStorage.setItem('mudavim_businessData', JSON.stringify(business));
	}, [business]);

	useEffect(() => {
		localStorage.setItem('mudavim_businessListData', JSON.stringify(businessList));
	}, [businessList]);

	const value = useMemo(
		() => ({
			business,
			setBusiness,
			businessList,
			setBusinessList,
			addBranch: (businessId: string, branch: BranchData) => {
				const updatedList = businessList.map((b) => {
					if (b.businessId === businessId) {
						return {
							...b,
							branchList: [...(b.branchList || []), branch],
						};
					}
					return b;
				});
				setBusinessList(updatedList);
			},
			updateBranch: (businessId: string, branch: BranchData) => {
				const updatedList = businessList.map((bus) => {
					if (bus.businessId === businessId) {
						return {
							...bus,
							branchList: bus.branchList?.map((b) => {
								if (b.uid === branch.uid) {
									return branch;
								}
								return b;
							}),
						};
					}
					return bus;
				});
				setBusinessList(updatedList);
			},
			deleteBranch: (businessId: string, branchId: string) => {
				const updatedList = businessList.map((bus) => {
					if (bus.businessId === businessId) {
						return {
							...bus,
							branchList: bus.branchList?.filter((b) => b.uid !== branchId),
						};
					}
					return bus;
				});
				setBusinessList(updatedList);
			},
		}),
		[business, businessList],
	);
	return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
};
BusinessContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default BusinessContext;

export const PHONE_MASK = '+\\9\\0 \\599 999 99 99';
export const MODAL_MODE_EDIT = 'EDIT';
export const MODAL_MODE_NEW = 'NEW';
export const SubscriptionTypes = [
	{ key: 'TRIAL', value: 'Trial', validMonthCount: 1 },
	{ key: 'QUARTERLY', value: '3Months', validMonthCount: 3 },
	{ key: 'HALF_YEARLY', value: '6Months', validMonthCount: 6 },
	{ key: 'YEARLY', value: 'Yearly', validMonthCount: 12 },
];
export const CampaignTypes = [{ key: 'LOYALTY_PROGRAM', value: 'LoyaltyProgram' }];
export const CampaignCategories = [
	{ key: 'Kahve', value: 'Coffee' },
	{ key: 'Tatlı', value: 'Desert' },
];
